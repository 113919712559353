import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { Select, TextField } from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { Box, Tooltip, InfoOutlineIcon, Grid } from 'src/ui'
import { representValue } from 'src/companies/configurations/utils'
import ConfigPanel, { PanelState } from '../config-panel'
import { useToast } from 'src/utils/toast'
import {
  useFindTenantBySlug,
  useGetConfigurables,
  useUpdateConfigurables,
} from 'src/api/queries/microservices/aggregator'
import { useQueryClient } from '@tanstack/react-query'

// DETAIL
///////////////////////////////////////////////////////////////////////////////
interface TenantConfigProps {
  tenantConfig: { [key: string]: TenantConfiguration[] }
}

function groupConfigurations(tenantConfig: TenantConfiguration[]): {
  [key: string]: TenantConfiguration[]
} {
  const sortedConfig = [...tenantConfig].sort((a, b) => a.group.localeCompare(b.group))
  const groupedConfigs = sortedConfig.reduce((acc, config) => {
    const { group } = config
    acc[group] = (acc[group] || []).concat(config)
    return acc
  }, {} as { [key: string]: TenantConfiguration[] })

  return groupedConfigs
}

const CompanyAggregatorConfigDetail = ({ tenantConfig }: TenantConfigProps) => {
  return (
    <Box>
      {Object.entries(tenantConfig).map(([groupName, groupConfigs], groupIndex) => {
        return (
          <Box bg={groupIndex % 2 === 1 ? 'gray.50' : undefined} key={groupName} flex={1} px={6}>
            <Grid templateColumns={`repeat(auto-fit, minmax(300px, 1fr))`}>
              {groupConfigs.map((config, index) => (
                <Box
                  key={config.key}
                  display="flex"
                  alignItems="center"
                  flex="1"
                  minWidth="0"
                  px="6"
                  py="2"
                >
                  {index === 0 ? (
                    <Box minH="40px" minWidth="150px" fontWeight="bold">
                      {groupName}
                    </Box>
                  ) : (
                    <Box minWidth="150px" />
                  )}
                  <Box>
                    <FormControl height="85px" mb={null}>
                      <FormLabel as="div" title="Label">
                        {config.label}
                        <Tooltip
                          label={config.description}
                          aria-label={config.description}
                          placement="top"
                        >
                          <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
                        </Tooltip>
                      </FormLabel>
                      <Box
                        flex={1}
                        minW={0}
                        color={config.value == 'null' ? 'gray.400' : undefined}
                      >
                        {representValue(config.value)}
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              ))}
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object({
  key: Yup.string().nullable(),
  value: Yup.string().nullable(),
})

interface AggregatorFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
  formFields: { [key: string]: TenantConfiguration[] }
}

const AggregatorForm = ({ formikProps, formData, formFields }: AggregatorFormProps) => {
  const { submittedAt } = formData
  const { submitForm, values } = formikProps

  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm, values]) //values need to properly submit form

  return (
    <Box>
      <Form>
        {Object.entries(formFields).map(([groupName, groupConfigs], groupIndex) => {
          return (
            <Box key={groupName} flex={1} px={6} bg={groupIndex % 2 === 1 ? 'gray.50' : undefined}>
              <Grid templateColumns={`repeat(auto-fit, minmax(300px, 1fr))`}>
                {groupConfigs.map((form, index) => (
                  <Box
                    key={form.key}
                    display="flex"
                    alignItems="center"
                    flex="1"
                    minWidth="0"
                    px="6"
                    py="2"
                  >
                    {index === 0 ? (
                      <Box minH="40px" minWidth="150px" fontWeight="bold">
                        {groupName}
                      </Box>
                    ) : (
                      <Box minWidth="150px" />
                    )}
                    <Box>
                      <FormControl height="85px" mb={null}>
                        <FormLabel as="div" title="Label">
                          {form.label}
                          <Tooltip
                            label={form.description}
                            aria-label={form.description}
                            placement="top"
                          >
                            <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
                          </Tooltip>
                        </FormLabel>
                        <Field
                          id={`fields.${groupName}.${index}.value`}
                          name={`fields.${groupName}.${index}.value`}
                          component={form.options && form.options.length > 0 ? Select : TextField}
                          formControlProps={{
                            height: '62px',
                            mb: null,
                          }}
                          options={
                            form.options
                              ? form.options.map((opt) => ({
                                  value: opt,
                                  label: opt,
                                }))
                              : null
                          }
                        />
                      </FormControl>
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Box>
          )
        })}
      </Form>
    </Box>
  )
}

type AggregatorPropertiesPanelProps = {
  company: Company
}

const AggregatorConfigPanel = ({ company }: AggregatorPropertiesPanelProps) => {
  const toast = useToast()
  const [aggregatorState, setAggregatorState] = React.useState<PanelState>('collapsed')
  const [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })
  const queryClient = useQueryClient()
  const { data: tenantBySlug } = useFindTenantBySlug(company.slug)
  const { data: tenant } = useGetConfigurables(company.slug)
  const [mergedConfig, setMergedConfig] = React.useState<TenantConfiguration[]>([])
  const { mutateAsync: saveAggregatorConfigsMutation } = useUpdateConfigurables(company.slug)

  const handleConfigSave = async (aggregatorConfig) => {
    await saveAggregatorConfigsMutation({ data: aggregatorConfig })
    await queryClient.invalidateQueries({ queryKey: ['aggregator-config-slug'] })
    await queryClient.invalidateQueries({ queryKey: ['aggregator-config-get'] })
    toast({ title: 'Success', description: 'Updated aggregator configurations' })
    stopEditing()
  }

  React.useEffect(() => {
    if (tenant && tenantBySlug) {
      const merged = tenant.map((item) => {
        const valueObj = tenantBySlug.find((dataItem) => dataItem.key === item.key)
        return { ...item, value: valueObj ? valueObj.value : undefined }
      })
      setMergedConfig(merged)
    }
  }, [tenant, tenantBySlug])

  const stopEditing = () => {
    setAggregatorState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  const groupedConfigs = groupConfigurations(mergedConfig)

  return (
    <ConfigPanel
      label="Web Portal/Aggregator"
      expanded={['expanded', 'editing'].includes(aggregatorState)}
      panelState={aggregatorState}
      formData={formData}
      copyPermission="company_properties_copy"
      editPermission="company_properties_edit"
      onChange={(e, expanded) => {
        if (aggregatorState === 'editing') return
        setAggregatorState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickEdit={() => setAggregatorState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
    >
      {aggregatorState !== 'editing' && (
        <CompanyAggregatorConfigDetail tenantConfig={groupedConfigs} />
      )}
      {aggregatorState === 'editing' && (
        <Formik
          initialValues={{ fields: groupedConfigs }}
          enableReinitialize={true}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={async (values, _formikActions) => {
            setFormData((state) => ({ ...state, isSubmitting: true }))
            const flattenedFields = Object.values(values.fields).flat() as TenantConfiguration[]
            Object.keys(flattenedFields).forEach((key) => {
              if (flattenedFields[key].value === null) {
                flattenedFields[key].value = 'null'
              }
            })
            try {
              await handleConfigSave(
                flattenedFields.map((field) => ({
                  key: field.key,
                  value: field.value,
                }))
              )
              _formikActions.setSubmitting(false)
            } catch (error) {
              toast({
                status: 'error',
                title: 'Error',
                description: error?.response?.data?.message || 'Failed to update aggregator config',
              })
              setFormData({ submittedAt: null, isSubmitting: false })
              _formikActions.setErrors(error?.response?.data?.validation_messages)
              _formikActions.setSubmitting(false)
            }
          }}
        >
          {(formikProps: FormikProps<any>) => (
            <AggregatorForm
              formikProps={formikProps}
              formData={formData}
              formFields={formikProps.values.fields}
            />
          )}
        </Formik>
      )}
    </ConfigPanel>
  )
}

export default AggregatorConfigPanel
